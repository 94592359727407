import React, { Component } from "react";
import { loadScript } from "@paypal/paypal-js";
class Pos extends Component {

  render() {
    loadScript({ "client-id": 'test' })
    .then((paypal) => {
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'silver',
          layout: 'horizontal',
          label: 'pay',
          
        },

        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [{"description":"Membership","amount":{"currency_code":"USD","value":60}}]
          });
        },

        onApprove: function(data, actions) {
          return actions.order.capture().then(function(orderData) {
            
            // Full available details
            console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

            // Show a success message within this page, e.g.
            const element = document.getElementById('paypal-button-container');
            element.innerHTML = '';
            element.innerHTML = '<h3>Thank you for your payment!</h3>';

            // Or go to another URL:  actions.redirect('thank_you.html');
            
          });
        },

        onError: function(err) {
          console.log(err);
        }
      }).render('#paypal-button-container');    })
    .catch((err) => {
        console.error("failed to load the PayPal JS SDK script", err);
    });

    return (
      <section id="pos">
        <div className="row">
          <div className="three columns header-col">
              <h1><span>Become a member</span></h1>
          </div>
          <cite>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</cite>
          <br/>
          <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">
                  <div id="smart-button-container">
                      <div id="paypal-button-container"></div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </section>
    );
  }
}

export default Pos;
