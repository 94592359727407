import React, { Component } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Updates from './Components/Updates';
import Join from './Components/Join';
import Pos from './Components/Pos';
import Bod from './Components/BOD';
import KidsCorner from './Components/KidsCorner';
import Contact from './Components/Contact';
import Testimonials from './Components/Testimonials';
import Portfolio from './Components/Portfolio';
import Ads from './Components/Ads';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      masconnData: {}
    };
  }

  getmasconnData(){
    $.ajax({
      url:'/MasconnData.json',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({masconnData: data});
      }.bind(this),
      error: function(xhr, status, err){
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount(){
    this.getmasconnData();
  }

  render() {
    return (
      <div className="App">
        <Header data={this.state.masconnData.main}/>
        <Updates data={this.state.masconnData.updates}/>
        <Bod data={this.state.masconnData.Bod}/>
        <KidsCorner data={this.state.masconnData.KidsCorner}/>
        <Portfolio data={this.state.masconnData.portfolio}/>
        {/* <Testimonials data={this.state.masconnData.testimonials}/> */}
        {/* <Pos data={this.state.masconnData.main}/> */}
        {/* <Ads data={this.state.masconnData.mob}/> */}
        {/* <Contact data={this.state.masconnData.main}/> */}
        <About data={this.state.masconnData.main}/>
        <Footer data={this.state.masconnData.main}/>
        {/* <Join /> */}
      </div>
    );
  }
}

export default App;
