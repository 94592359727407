import React, { Component } from 'react';

class Bod extends Component {
  
  render() {

    const currentYear = new Date().getFullYear(); 

    if(this.props.data){
      var exec = this.props.data.exec.map(function(bod){
        var image = 'images/bod/'+bod.image;
        console.log(image);
        return  <li key={bod.title}>
            <img alt={bod.name} src={image} />
            <p className="center-align">{bod.name}<br/><b>{bod.title}</b></p>

         </li>
      })
      var bod = this.props.data.bod.map(function(bod){
        var image = 'images/bod/'+bod.image;
        return  <li key={bod.title}>
            <img alt={bod.name} src={image} />
              <p className="center-align">{bod.name}<br/><b>{bod.title}</b></p>

         </li>
      })
      var advisory = this.props.data.advisory.map(function(bod){
        var image = 'images/bod/'+bod.image;
        return  <li key={bod.title}>
            <img alt={bod.name} src={image} />
            <p className="center-align">{bod.name}<br/><b>{bod.title}</b></p>

          </li>
      })

    }

    return (
      <section id="bod">
        <div className="row section-head">
            <h1>
              <span>Our team - {currentYear}</span>
            </h1>
        </div>
        <div className="text-container">
          <div className="row">
              <div className="twelve columns flex-container">
                <h2>
                  <span>Executive board</span>
                </h2>
                <ul className="slides">
                  {exec}
                </ul>
                <h2>
                  <span>Additional Board of Directors</span>
                </h2>
                <ul className="slides">
                  {bod}
                </ul>
                <h2>
                  <span>Advisory board</span>
                </h2>
                <ul className="slides">
                  {advisory}
                </ul>
                </div>
              </div>
        </div>
        <hr></hr>
      </section>
    );
  }
}

export default Bod;