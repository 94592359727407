import React, { Component } from 'react';

class KidsCorner extends Component {
  render() {

    if(this.props.data){
      var bod = this.props.data.bod.map(function(bod){
        var image = 'images/kidsclub/bod/'+bod.image;
        return  <li key={bod.title}>
            <img alt={bod.name} src={image} />
              <p>{bod.name}<br/><b>{bod.title}</b></p>
         </li>
      })
      var kidsclubForms = this.props.data.forms.map(function(form){
        return <li key={form.name}>
            <p><a className="form" href={form.URL}  target="_blank">{form.name}</a></p>
        </li>
      })
    }

    return (
      <section id="kidscorner">
        <div className="text-container">
          <div className="row">
              <div className="twelve columns flex-container">
                <h1>
                  <span>Kids club</span>
                </h1>
                <h2>Forms</h2>
                <span class="extra-info">
                  <i class="fa fa-info-circle fa-sm" aria-hidden="true">  If you wish to submit the below forms offline, please fill out a printed copy and mail it to the address mentioned at the bottom of this page.</i>
                </span>
                <ul id="forms">
                  {kidsclubForms}
                </ul>
                <h2>
                  <span>Executive board</span>
                </h2>
                  <ul className="slides">
                      {bod}
                  </ul>
                </div>
              </div>
        </div>
      </section>
    );
  }
}

export default KidsCorner;