import React, { Component } from "react";

class Ads extends Component {
  render() {
    if(this.props.data){
      var mob = this.props.data.mob.map(function(mob){
         var image = 'images/mob/'+mob.image;
        return  <li key={mob.user}>
            <img alt={mob.address} src={image} />
            <blockquote>
               <p>{mob.address}</p>
               <cite>{mob.name}</cite>
            </blockquote>
         </li>
      })
    }

    return (
      <section id="mob">
        <div className="row section-head">
          <h1>
            <span>Malayalee owned business</span>
          </h1>
      </div>
      <div className="text-container">
         <div className="row">
            <div className="twelve columns flex-container">
                  <ul className="slides">
                      {mob}
                  </ul>
               </div>
            </div>
         </div>
   </section>
    );
  }
}

export default Ads;
