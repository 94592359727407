import React, { Component } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

class Updates extends Component {
   
  render() {

   if(this.props.data){
      var sliderData = this.props.data.sliderData.map(function(sliderData){
        return <div data-src={sliderData.url} />
      })
      var standings = this.props.data.standings.map(function(standings){
        return <div className={standings.teamName}><p>{standings.teamName}&nbsp;&nbsp;<span className='score'>{standings.score}</span></p></div>
      })
      var notification = this.props.data.notification.map(function(notification){
         return <span>{notification.content}<br/><a href={notification.url}>More..</a></span>;
      })

      //For slider
      // var updates = this.props.data.imageBanner.map(function(imageBanner){
      //    return <div data-src={imageBanner.image} />
      // })
      //For static image
      var updates = this.props.data.imageBanner.map(function(imageBanner){
         if(imageBanner.url)
            return <a href={imageBanner.url} target='_blank'><img src={imageBanner.image}/></a>
         else
            return <img src={imageBanner.image}/>
      })
      
    }

    const AutoplaySlider = withAutoplay(AwesomeSlider);

    return (
      <section id="updates">
         {/* <h2>Notifications</h2>
         <div className='row'>
            <div className='notification'>
               {notification}<br/>
              <i className="fa fa-bell"></i>
            </div>
         </div> */}
         {updates!= undefined && updates.length > 0 ? 
            <div className="row">
               <div className="updates-banner">
               {updates}
               </div>

               {/* <AutoplaySlider play={true}
                  cancelOnInteraction={false} // should stop playing on user interaction
                  interval={6000}
                  mobileTouch={true}
                  animation="cubeAnimation">
                  {updates}
               </AutoplaySlider> */}
            </div>
            :
            <></>
         }

         {/* <h2>Team updates</h2>
         <div className='row'>
            <div className="standings">
               {standings}
            </div>
         </div>
         */}
         {sliderData!= undefined && sliderData.length > 0 ? 
            <div className="row">
               <AutoplaySlider play={true}
                  cancelOnInteraction={false} // should stop playing on user interaction
                  interval={6000}
                  mobileTouch={true}
                  animation="cubeAnimation">
                  {sliderData}
               </AutoplaySlider>
            </div>
            :
            <></>
         } 
   </section>
    );
  }
}

export default Updates;
